@use '../../scss/' as *;
.tf-section.how-we-work {
  padding: 80px 0 52px;
}

/* Responsive box styles */
.responsive-box {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 120px;
  box-sizing: border-box;
  text-align: center;
}

/* Ensure the text is centered */
.responsive-box .effect {
  display: inline-block;
  width: 100%;
  text-align: center;
}

/* Additional responsive styling */
// @media (max-width: 600px) {
//   .responsive-box {
//       max-width: 100%;
//       padding: 8px 16px; /* Adjust as needed for smaller screens */
//   }
// }


.col-5 {
  flex: 0 0 20%;
  max-width: 20%;
  padding: 15px; // Adjust padding to ensure space between columns

  @media (max-width: 1200px) {
      flex: 0 0 25%;
      max-width: 25%;
  }

  @media (max-width: 992px) {
      flex: 0 0 33.3333%;
      max-width: 33.3333%;
  }

  @media (max-width: 768px) {
      flex: 0 0 50%;
      max-width: 50%;
  }

  @media (max-width: 576px) {
      flex: 0 0 100%;
      max-width: 100%;
  }
}
