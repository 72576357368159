@use '../../../scss/' as *;

.post-item {
    min-height: 426px;
    @include transition3;
    position: relative;
    border: 2px solid transparent;
    &.active,
    &:hover {
        &::before {
            border: 1px solid rgba(34, 183, 143, 0);
            z-index: -999;
        }
        box-shadow: 0 0px 32px 0 rgb(34 183 143 / 50%);
        border: 2px solid $bg-2;
        .wrap {
            margin: 0;
        }
    }
    &::before {
        @include transition5;
        position: absolute;
        content: "";
        border: 1px solid rgba(34, 183, 143, 0.5);
        top: -1px;
        left: -1px;
        width: calc(100% + 2px);
        height: calc(100% + 2px);
        z-index: 999;
        background: transparent;
    }
    .image {
        z-index: 6;
        background: $bg-main;
        img {
            width: $w100;
        }
    }
    .tag {
        position: absolute;
        top: 21px;
        left: 21px;
        z-index: 999;
        color: #fff;
        background: #14C2A3;
        height: 32px;
        padding: 0 12px;
        font-size: 14px;
        line-height: 1.4;
        text-transform: capitalize;
        @include flex(center,center);
    }
    .wrap {
        position: relative;
        z-index: 10;
        &.active,
        &:hover {
            &::before {
                border-color: #14C2A3;
            }
        }
    }

    .content-item {
        position: absolute;
        left: 0;
        bottom: -3px;
        width: 100%;
        height: auto;
        z-index: 0;
        .content {
            width:$w100;
            height:$w100;
            position: relative;
            padding: 22px 18px 20px 20px;
            z-index: 1;
        
            &::before {
                background: rgba(34, 183, 143, 0.1);
                backdrop-filter: blur(10px);
                position: absolute;
                content: "";
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                z-index: -1;
            }
            .meta-post {
                font-size: 14px;
                line-height: 1.57;
                margin-bottom: 3px;
                color: $white;
            }
            .h7 {
                letter-spacing: 0;
                margin-bottom: 17px;
            }
            p {
                margin-top: 17px;
                margin-bottom: 0;
            }
        }
    }

    
}

.home-2 , .home-3, .item-post {
    .content-item .content {
        .h7 {
            margin-bottom: 0;
        }

        p {
            display: none;
        }
    }
    
}
.item-post .tf-title.st2 {
    text-align: center;
}

.post-details {
    .post-item {
        min-height: 100%;
    }
    @include tablet {
        .swiper-button-prev,.swiper-button-next {
            display: none;
        }
    }
    
}